.Header {
  padding: 1.618em;
  width: 25%;
  cursor: pointer;
}

.Container {
  max-width: 75em;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Raleway', sans-serif;
  color: #153459;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

input[type="text"] {
  font-size: 1.5rem;
  padding: 0.5rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
  width: 100%;
}

button[type="submit"] {
  background-color: #1775a9;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #153459;
}
